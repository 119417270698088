import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";

import Layout from "../layout";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default ({ uri }) => {
  const [sent, Send] = useState(false);
  const [loading, Load] = useState(false);
  const nameRef = useRef();
  const emailRef = useRef();
  const companyRef = useRef();
  const messageRef = useRef();

  const sendMessage = e => {
    e.preventDefault();
    let name = nameRef.current.value;
    let email = emailRef.current.value;
    let company = companyRef.current.value || "";
    let message = messageRef.current.value;
    let form = e.target;
    let formData = {
      name,
      email,
      company,
      message,
    };
    Load(true);
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(res => Send(true))
      .catch(err => {
        console.log(err);
        Send(true);
      });
  };
  return (
    <Layout path={uri}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>Mohamed's Porfolio &#129305;</title>
        <meta
          name="description"
          content="Full Stack Web Developer Portfolio."
        />
        <meta name="robots" content="nofollow" />
      </Helmet>
      {sent ? (
        <div className="thankyou fade">
          <b>Thanks for your message.</b>
        </div>
      ) : (
        <div className="contact fade">
          <div className="form-div">
            <form
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              onSubmit={sendMessage}
            >
              <div className="input-group">
                <label className="required">Full Name</label>
                <br />
                <input
                  disabled={loading}
                  type="text"
                  name="name"
                  required
                  ref={nameRef}
                />
              </div>
              <div className="input-group">
                <label className="required">Email</label>
                <br />
                <input
                  disabled={loading}
                  type="email"
                  name="email"
                  required
                  ref={emailRef}
                />
              </div>
              <div className="input-group">
                <label>Company Name</label>
                <br />
                <input
                  disabled={loading}
                  type="text"
                  name="company"
                  ref={companyRef}
                />
              </div>
              <div className="input-group">
                <label className="required">Message</label>
                <br />
                <textarea name="message" required ref={messageRef}></textarea>
              </div>
              <div className="input-group">
                <input
                  disabled={loading}
                  type="submit"
                  name="submit"
                  value="Send"
                />
              </div>
            </form>
            <div className="right">
              <div>
                Github profile{" "}
                <a
                  href="https://github.com/fallmo"
                  className="normal"
                  target="_blank"
                >
                  Here
                </a>
                . <small>(Most projects are not publicly visible)</small>
              </div>
              <div>
                Contact me directly for work, feedback, or github
                collaborations.
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
